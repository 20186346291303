<template>
	<div v-bind:class="getBackgroundClass()">
		<div id="speakers">
			<!-- <h2>About Speakers</h2> -->
			<h2 class="speaker-title">Our 2023 Speakers</h2>
			<div v-bind:class="getBackgroundClass()">
				<div class="grid" ref="grid">
					<div :class="getLayout(i)" v-for="(speaker, name, i) in speakers" :key="speaker">
						<!-- PHOTO -->
						<img :src="getImage(name)" :alt="name + '\'s Photo'" class="image-speaker" />
						<div class="speaker-info">
							<!-- NAME -->
							<h3 class="speaker-name">{{ name }}</h3>
							<!-- COMPANY AND POSITION -->
							<h4 class="speaker-company">{{ speaker.title }} @ {{ speaker.org }}</h4>
							<!-- BIO -->
							<p class="speaker-bio">{{ speaker.bio }}</p>
						</div>
					</div>
				</div>

				<h3 v-if="!limit" class="coming-soon">
					More speakers coming soon!
				</h3>
				<div v-else class="view-more">
					<router-link class="view-speakers" to="/speakers">View all speakers</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// Copied from https://www.notion.so/justinachua/c21925a8417546619ac10ecc28ebeba5?v=e69ab087042641d895b9ac9a4f233c9a
import SpeakersContent from "@/content/speakers.json";

export default {
	name: "Speakers",
	props: {
		limit: Number,
		hasBackground: {
			default: false,
			type: Boolean
		}
	},
	data() {
		return {
			speakers: SpeakersContent,
			colors: ["#8394F2", "#F57A75", "#17ADCE", "#F9AFAB", "#44AF69"]
		};
	},
	methods: {
		getImage(name) {
			try {
				// speaker name is the exact same as the file path in speaker assets
				return require(`@/assets/speakers/Speaker=${name}.png`);
			} catch (e) {
				// throw Error(`pic does not exist: ${name}`);
				// image placeholder when name does not match the filename of a headshot
				return require(`@/assets/speakers/Speaker=Default.png`);
			}
		},
		getLinkImage(linkType) {
			try {
				return require("@/assets/speakers/links/" + linkType + ".svg");
			} catch (e) {
				// throw Error(`link type does not exist: ${linkType}`);
			}
		},
		getLayout(i) {
			if (i % 2) return "right-image"
			else return "left-image"
		},
		getBackgroundClass() {
			if (this.hasBackground) return "background"
			else return "whiteBackground"
		}
	},
	beforeMount() {
		if (this.limit) {
			const sp = Object.entries(this.speakers).slice(0, this.limit);
			this.speakers = Object.fromEntries(sp);
		}
		else{
			const sp = Object.entries(this.speakers);
			sp.splice(-3); // removing founders from the main speakers list
			this.speakers = Object.fromEntries(sp);
		}
	},
};
</script>

<style scoped>
#speakers {
	--icon-width: 1.5rem;
	--tile-radius: 1rem;
	--bio-transition: all 0.5s ease;
	width: 80%;
	max-width: 1200px;
	margin: auto;
	margin-top: 125px;
	padding-top: 0.1vh;
}

.background {
	background: var(--main-gradient);
	background-attachment: fixed;
	padding-bottom: 5vh;
	color: white;
}

.background h2 {
	color: white;
}

.whiteBackground h2 {
	color: black;
}

.whiteBackground {
	color: black;
	background-attachment: fixed;
	padding-bottom: 5vh;
}

.grid {
	display: grid;
	grid-template: repeat(3, auto) / 1fr;
	gap: 125px;
	font-size: min(1vw, 0.85rem);
	overflow-x: hidden;
}

.right-image,
.left-image {
	position: relative;
	display: grid;
	grid-template: 1fr / 50% 50%;
	height: 20rem;
	column-gap: 0.5rem;
	width: 100%;
	overflow: hidden;
}

.view-more {
	margin-top: 136px;
}

.view-speakers {
	color: white;
	font-weight: 700;
	font-size: 20px;
	background: #B92F21;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 360px;
	height: 56px;
	cursor: pointer;
	text-decoration: none;
	padding: 0;
}

@media screen and (max-width: 900px) {
	.view-speakers {
		font-size: 1rem;
	}
}


.image-speaker {
	height: 100%;
	max-width: 100%;
	object-fit: cover;
	transition: var(--bio-transition);
	grid-column: 1/2;
	grid-row: 1/4;
	justify-self: center;
	align-self: center;
}

.left-image>.image-speaker {
	grid-column: 1/2;
}

.right-image>.image-speaker {
	grid-column: 2/3;
}

.speaker-info {
	display: grid;
	grid-template: 1fr auto 5fr / 1fr;
	row-gap: 0.5rem;
	align-self: center;
}

.left-image>.speaker-info {
	margin-left: 2rem;
}

.speaker-name {
	grid-row: 1/2;
}

.speaker-bio {

	text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		max-height: 16em;
		line-height: 2em;

		display: -webkit-box; 
		-webkit-line-clamp: 8; 
		-webkit-box-orient: vertical;
}

h2 {
	padding-top: 2vh;
	margin-top: 0px;
	margin-bottom: 2em;
}

p {
	font-size: 1em;
}

h3 {
	font-size: 2em;
	font-weight: 700;
}

h4 {
	font-size: 1.5em;
	font-weight: 600;
}

.coming-soon {
	margin-top: 3rem;
	text-align: center;
	font-weight: 600;
}

@media screen and (max-width: 1200px) {
	.grid {
		font-size: 1.4vw;
	}
}

@media screen and (max-width: 800px) {
	.grid {
		font-size: 1.6vw;
	}
}

@media screen and (max-width: 650px) {
	.grid {
		font-size: 1.8vw;
	}

	.image-speaker {
		max-height: 320px;
	}
}

@media screen and (max-width: 550px) {
	.grid {
		font-size: 2vw;
	}

	h2 {
		margin-bottom: 1em;
	}
}

@media screen and (max-width: 450px) {
	.grid {
		font-size: 2.2vw;
	}
}

@media screen and (max-width: 380px) {

	.grid {
		gap: 52px;
	}

	.left-image,
	.right-image {
		grid-template: auto 1fr / 1fr;
	}

	.image-speaker {
		grid-row: 1/3;
	}

	.right-image > .image-speaker {
		grid-column: 1/3;
	}

	.left-image>.speaker-info {
		margin-left: 0;
	}

	.speaker-info {
		text-align: center;
		grid-template: 1fr auto auto / 1fr;
		margin-top: 16px;
	}

	.speaker-bio {
		text-overflow: ellipsis;
		word-wrap: break-word;
		overflow: hidden;
		max-height: 3.3em;
		line-height: 1.1em;

		display: -webkit-box; 
		-webkit-line-clamp: 3; 
		-webkit-box-orient: vertical;
	}

	h3 {
		font-size: 2.5em;
	}

	h4 {
		font-size: 2em;
	}

	p {
		font-size: 1.75em;
		font-weight: 400;
		color: #C3CDDB;
	}

	.view-more {
		margin-top: 40px;
	}

	.background {
		padding-bottom: 2vh;
	}
}</style>
