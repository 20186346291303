<template>
	<span style="scroll" id="home-page">
		<div class="fade">
			<nav-bar :hasBackground="false" />
			<hero />
		</div>

		<div class="home-content">
			<about />
			<hubs />
		</div>
			<speakers :limit="3" :hasBackground="true" />
			<whyus :hasBackground="true" />

		<div class="home-content">
			<!-- <h2> 2023 Schedule Coming Soon ! </h2> -->
			<!-- <schedule/> -->
			<!-- <Video /> -->
			<!-- <schedule-preview /> -->
			<!-- <testimonials /> -->
			<sponsorship />
			<meettheteam :limit="8"/>
			<!-- <blog :limit="6" /> -->
			<faq />
			<!-- <contributors /> -->
		</div>
		<bottom />
	</span>
</template>

<script>
import NavBar from "@/components/general/NavBar.vue";
import Hero from "@/components/homePage/Hero.vue";
import About from "@/components/homePage/AboutUpdated.vue";
import Hubs from "@/components/homePage/Hubs.vue";
import Speakers from "@/components/general/SpeakersUpdated.vue";
import Whyus from "@/components/general/WhyUs.vue";
// import Schedule from "@/components/homePage/Schedule.vue";
// import Video from "@/components/homePage/Video.vue";
// import SchedulePreview from "@/components/homePage/SchedulePreview.vue";
// import Testimonials from "@/components/homePage/Testimonials.vue";
import Sponsorship from "@/components/homePage/Sponsorship.vue";
import Meettheteam from "@/components/general/MeetTheTeam.vue";
// import Blog from "@/components/general/Blog.vue";
import Faq from "@/components/homePage/Faq.vue";
// import Contributors from "@/components/homePage/Contributors.vue";
import Bottom from "@/components/general/Footer.vue";

export default {
	name: "Home",
	components: {
		NavBar,
		Hero,
		About,
		Hubs,
		Speakers,
		Whyus,
		// Schedule,
		// Video,
		// SchedulePreview,
		// Testimonials,
		Sponsorship,
		Meettheteam,
		// Blog,
		Faq,
		// Contributors,
		Bottom
	}
};
</script>

<style>
/* .home-content {

	margin-top: 125px;
} */

.primaryBtn {
	color: white;
	font-weight: 700;
	font-size: 20px;
	background: #B92F21;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: 360px;
	height: 56px;
	text-decoration: none;
	cursor: pointer;
}

#home-page h2 {
	margin-top: 2em;
}

.fade {
	/* background: linear-gradient(
	180deg,
	#ff4e4e 0%,
	#ffaeae 66.67%,
	#ffc5c5 77.6%,
	#ffd8d8 87.5%,
	#ffffff 100%
	); */

	background: linear-gradient(118.42deg, #000000 19.78%, #12446D 108.41%);
}


@media screen and (max-width: 850px) {
	/* .fade {
		background: linear-gradient(
			180deg,
			#ff4e4e 5%,
			#ffaeae 66.67%,
			#ffc5c5 77.6%,
			#ffd8d8 90%,
			#ffffff 100%
		); 
	} */
	.fade {
		background: linear-gradient(118.42deg, #000000 19.78%, #12446D 108.41%);
	}
}
</style>
