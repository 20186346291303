<template>
    <div class="why-us">
        <div class="divider"></div>
        <div class="why-us-header">Why Us?</div>
        <div class="why-us-grid">
            <div class="why-us-item">
                <img src="@/assets/why-us/connect.png" class="item-image" />
                <div class="item-heading">
                    Connect
                </div>
                <div class="item-desc">
                    Meet tech enthusiasts across Canada from diverse backgrounds and experiences.
                </div>
            </div>
            <div class="why-us-item">
                <img src="@/assets/why-us/create.png" class="second-img" />
                <div class="item-heading">
                    Create
                </div>
                <div class="item-desc">
                    Pitch your startup projects at our competition, and bring ideas to life.
                </div>
            </div>
            <div class="why-us-item">
                <img src="@/assets/why-us/reignite.png" class="item-image" />
                <div class="item-heading">
                    Re-Ignite
                </div>
                <div class="item-desc">
                    Spark new passions in cross-sectional fields, gain a variety of technical skills, and become inspired by
                    successful trailblazers.
                </div>
            </div>
        </div>
    </div>
</template>


<style scoped>
.why-us {
    background: var(--main-gradient);
    background-attachment: fixed;
    color: white;
    padding: 0 166px 5vh 166px;
    border: none;
    margin-top: -1px;
}

.divider {
    background-color: rgba(255, 255, 255, 0.44);
    width: 100%;
    height: 1px;
    margin: auto auto 117px auto;
}

.why-us-header {
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
}

.why-us-grid {
    display: grid;
    grid-template: 1fr / 1fr 1fr 1fr;
    margin: 70px 0;
    gap: 175px;
}

.item-image {
    margin-bottom: 62px;
    width: 154px;
    height: 154px;
}

.second-img {
    margin-bottom: 33px;
}

.item-heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 23px;
}

.item-desc {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

@media screen and (max-width: 1190px) {

    .divider {
        margin-bottom: 75px;
    }

    .why-us-grid {
        gap: 75px;
    }

    .item-image {
        width: 100px;
        height: 100px;
    }

    .second-img {
        width: 129px;
        height: 129px;
    }
}

@media screen and (max-width: 900px) {
    .why-us {
        padding: 0 100px 5vh 100px;
    }
    .why-us-header {
        text-align: center;
    }

    .why-us-grid {
        display: block;
        margin-top: 20px;
    }

    .why-us-item {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
    }

    .item-image, .second-img {
        margin: 20px auto 10px auto;
        justify-self: center;
    }

    .item-heading, .item-desc {
        text-align: center;
    }
}

@media screen and (max-width: 350px) {
    .why-us {
        padding: 0 80px 5vh 80px;
    }
    .why-us-header {
        font-size: 40px;
    }

    .item-image {
        width: 75px;
        height: 75px;
    }

    .second-img {
        width: 104px;
        height: 104px;
    }

    .item-heading {
        font-size: 25px;
    }

    .item-desc {
        font-size: 13px;
    }
}

@media screen and (max-width: 275px) {
    .why-us {
        padding: 0 60px 5vh 60px;
    }
}

</style>
