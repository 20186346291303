<template>
	<div class="footer-position">
		<div class="footer-background">
			<div class="footer-content">
				<div class="footer-content-container">
					<div class="footer-content-left">
						<router-link class="footer-link" to="/speakers">Speakers</router-link>
						<router-link class="footer-link" to="/schedule">Schedule</router-link>
						<router-link class="footer-link" to="/blog">Blog</router-link>
						<!-- <a class="footer-link" href="">Speakers</a>
						<a class="footer-link" href="">Schedule</a>
						<a class="footer-link" href="">About Us</a> -->
					</div>
					<div class="footer-content-right">
						<router-link class="footer-link" to="/">Contact Us</router-link>
						<router-link class="footer-link" to="/">FAQs</router-link>
						<router-link class="footer-link" to="/">Sponsors</router-link>
						<!-- <a class="footer-link" href="">Contact Us</a>
						<a class="footer-link" href="">FAQs</a>
						<a class="footer-link" href="">Sponsors</a> -->
					</div>
				</div>

				<hr class="line">


				<div class="footer-content-container">
					<div class="footer-content-left followbtns">
						<p class="contact">Follow Us</p>
						<div class="cutc-icons">
							<a
								v-for="item in items"
								:key="item.link"
								:href="`${item.link}`"
								target="_blank"
								class="icons"
							>
								<img :src="getImage(item.image)" />
							</a>
						</div>
					</div>
					<div class="footer-content-right">
						<div v-if="signUpForm" id="mc_embed_signup">
							<form action="https://cutc.us10.list-manage.com/subscribe/post?u=73d566326ae6bb629b42fb5fd&amp;id=e28a4a51fe&amp;f_id=007f2de2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" v-on:submit="subscribeSubmit">
								<h4>Subscribe</h4>
								<div id="mc_embed_signup_scroll">
									<div class="mc-field-group" style="margin-bottom: -16px;">
										<!-- <label for="mce-EMAIL">
											Email Address  
											<span class="asterisk">*</span>
										</label> -->
										<input type="email" value="" placeholder="Email address" name="EMAIL" class="required email emailFeild" id="mce-EMAIL" required>

										<div id="mce-responses" class="clear foot">
											<div class="response" id="mce-error-response" style="display:none"></div>
											<div class="response" id="mce-success-response" style="display:none"></div>
										</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
										
									</div>
									<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_73d566326ae6bb629b42fb5fd_e28a4a51fe" tabindex="-1" value=""></div>
										<div class="optionalParent">
											<div class="clear">
													<input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" class="button mailButton">
													<p class="brandingLogo">
														<a href="http://eepurl.com/icyIb5" title="Mailchimp - email marketing made easy and fun">
															<img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg">
														</a>
													</p>
											</div>
									</div>
								</div>
							</form>
						</div>
						<div v-if="signUpSuccess" id="signup-success">
							<div>
								<img src="@/assets/misc/correct.png" id="cutc-building-image" />
							</div>
							<div>
								Thank you for subscribing to our mailing list.
							</div>
						</div>
					</div>
				</div>
				<p class="copyright">&copy; 2022 CUTC. All rights reserved</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Bottom",
	data() {
		return {
			items: [
				// {
				// 	link: "https://www.facebook.com/cutc2021/",
				// 	image: "facebook.svg"
				// },
				{
					link: "https://www.instagram.com/cutc2023/",
					image: "instagram.svg"
				},
				{
					link: "https://www.youtube.com/channel/UCdVC7kW_5ybQ7qrbH0UzCLQ/featured",
					image: "youtube.svg"
				},
				{
					link: "https://www.linkedin.com/company/canadian-undergraduate-technology-conference/",
					image: "linkedin.svg"
				}
			],
			signUpForm: true,
      signUpSuccess: false
		};
	},
	methods: {
		getImage(name) {
			return require("@/assets/socials/" + name);
		},
		subscribeSubmit(e){
      e.preventDefault()
			this.signUpForm = false
      this.signUpSuccess = true
      // e.target.submit()
    }
	}
};
</script>

<style scoped>
.footer-position {
	--footer-height: 27rem;
	height: calc(var(--footer-height));
}

.footer-content-container {
	white-space: nowrap; 
	justify-content: space-between;
	width: 60vw;
	margin-left: 10vw;
	margin-right: 10vw;
	margin-top: 6vh;
	margin-bottom: 5vh;
}

@media screen and (max-width: 850px) {
	.footer-position {
		--footer-height: 35rem;
		height: calc(var(--footer-height));
	}
	.footer-content-right {
		margin-top: 2vh;
	}

	.footer-content-container {
		margin-bottom: 3vh;
	}
}

.footer-link {
	color: white;
	text-decoration: none;
	margin-right: 2.5vw;
	font-weight: bold;
}
.line {
	width: 70vw;
	margin-bottom: 4vh;
}
.copyright {
	margin-top: 3vh;
	margin-bottom: 5vh;
}
.footer-background {
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	align-items: center;
	/* background: linear-gradient(180deg,#fff -.11%,#ff9d9d 32.5%,#ff5050); */
	background: var(--main-gradient);
	height: var(--footer-height);
}
.footer-content {
	text-align: center;
	margin: 0 auto;
	width: 50%;
	min-width: max-content;
}
.cutc-icons {
	margin-bottom: 0.5rem;
}
.icons:not(:first-child) {
	margin-left: 0.75rem;
}
.contact {
	margin: 0.5rem 0;
}

#mc_embed_signup{
	background: transparent;
	clear:left; 
	/* font:14px Helvetica,Arial,sans-serif;   */
	/* width:350px; */
  display: flex;
}

#mc_embed_signup form {
  text-align: center;
}

#mc_embed_signup h2{
	margin: 0px;
	color: white;

}

.brandingLogo{
	display: none;
}

@media screen and (min-width: 850px) {
	#mc_embed_signup_scroll{
		display: flex;
	}

	.footer-content-container{
		display: flex;
	}
}



.emailFeild{
	display: flex;
}

#mc_embed_signup .button {
	text-decoration: none;
	font-weight: 500;
	text-align: center;
	height: 37.43px;
	color: white;
	background: #B92F21;
	border-radius: 4px;
	cursor: pointer;
}

.optionalParent{
	margin-top: 8px;
  margin-left: 10px;
	text-align: center;
}

.followbtns{
	margin-top: 18px;
}

#signup-success {
  text-align: center;
}

#signup-success img{
  width: auto;
  height: 50px;
  margin-bottom: 10px;
}



</style>
