<template>
  <h2 class="hubs-heading" style="margin-top: 1rem">Join us at our Local Networking Hubs</h2>
<!--   
  <div class="button-holder">
    <a target="Blank" href="https://www.eventbrite.com/e/toronto-networking-hub-x-unity-tickets-436238118957">
      <button class="hubButton" >
        <b>TORONTO</b>
        <br/>
        <span>Nov 8th, 2022</span>
      </button>
    </a>

    <a target="Blank" href="https://www.eventbrite.com/e/waterloo-networking-hub-tickets-444659658007?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=escb">
      <button class="hubButton">
        <b>WATERLOO</b>
        <br/>
        <span>Nov 11th, 2022</span>
      </button>
    </a>

    <a target="Blank" href="https://www.eventbrite.com/e/cutc-networking-hub-windsor-tickets-448699731977">
      <button class="hubButton">
        <b>WINDSOR</b>
        <br/>
        <span>Nov 18th, 2022</span>
      </button>
    </a>
    
  </div> -->


  <div class="hubs-wrapper" id="hubs">
    <div class="grid" ref="grid">

      <div class="tile">
        <a target="Blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7002993432314986496">
        <!-- PHOTO -->
        
          <div class="image-wrapper">
            <div
              class="image-background positioning"
              :style="{ background: '#8394F2' }"
            />
            <img
              :src="getImage('toronto')"
              class="image-hub"
              width="288"
              height="432"
            />
          </div>
        </a>

        <!-- OVERLAY -->
        <div class="overlay">
          <div class="text">
            <h3>Toronto</h3>
            <p>
              November 8th, 2022
            </p>
          </div>
          
        </div>
      </div>
  
      <div class="tile">
        <a target="Blank" href="https://www.eventbrite.com/e/cutc-x-tech-uw-networking-hub-tickets-519111234677">
          <!-- PHOTO -->
          <div class="image-wrapper">
            <div
              class="image-background positioning"
              :style="{ background: '#F57A75' }"
            />
            <img
              :src="getImage('waterloo')"
              class="image-hub"
              width="288"
              height="432"
            />
          </div>
        </a>
        <!-- OVERLAY -->
        <div class="overlay">
          <div class="text">
            <h3>Waterloo</h3>
            <p>
              February 6th, 2022
            </p>
          </div>
          
        </div>
      </div>
  
      <div class="tile">
        <a target="Blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7002858911414312960/">
          <!-- PHOTO -->
          <div class="image-wrapper">
            <div
              class="image-background positioning"
              :style="{ background: '#17ADCE' }"
            />
            <img
              :src="getImage('windsor')"
              class="image-hub"
              width="288"
              height="432"
            />
          </div>
        </a>
        <!-- OVERLAY -->
        <div class="overlay">
          <div class="text">
            <h3>Windsor</h3>
            <p>
              November 18th, 2022
            </p>
          </div>
          
        </div>
      </div>
    </div>
	</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getImage(logo) {
      try {
        return require(`@/assets/hubs/${logo}.png`);
      } catch (e) {
        throw Error(`pic does not exist: ${logo}`);
      }
    },
  },
};
</script>

<style scoped>

.hubs-heading {
  width: 80%;
	max-width: 1200px;
	margin: auto;
}
/* shrink logo and gradient width on mobile */

@media screen and (min-width: 950px) {
  
  .button-holder {
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
    display: flex;
    justify-content: space-around;
  }
}

.hubs-wrapper {
	max-width: 1120px;
	width: 80%;
	margin: 2rem auto 8rem auto;
}


.hubButton {
  margin-bottom: 20px;
  margin-right: 20px;
	background: #e84545;
	border-radius: 20px;
	border: none;
	padding: 1em 2em;
	font-weight: 500;
  font-size: 20px;

	cursor: pointer;
	text-decoration: none;
	color: white;
}

.button-holder {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 100px;
  }


  #hubs {
	/* --icon-width: 0.5rem; */
	--tile-radius: 1rem;
	--bio-transition: all 0.5s ease;
}

.grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 2rem 4vw;
	font-size: min(1vw, 0.85rem);
}
.tile {
	position: relative;
}

.image-wrapper {
	position: relative;
	width: calc(100% - var(--icon-width) - 0.5rem);
	height: 100%;
	overflow: hidden;
  transition: transform .2s;
  opacity: 0.95;
}
.image-wrapper > .positioning {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 80%;
}
.image-background {
	border-radius: var(--tile-radius);
	z-index: -1;
	transition: var(--bio-transition);
}
.image-hub {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: var(--tile-radius);
	transition: var(--bio-transition);
}



.tile > .overlay {
	position: absolute;
	right: 0;
	bottom: -2rem;
	width: 90%;
	max-width: 80%;

	display: flex;
	flex-direction: column-reverse;
	align-items: flex-end;
}
.overlay > .text {
	background: white;
	box-shadow: 0 2px 4px #00000040;
	border-radius: 16px;
	padding: 0.5em 1em;
	width: max-content;
	max-width: 100%;
}
.text > p {
	line-height: 1.3;
}
/* Link image */
.overlay > a > img {
	width: var(--icon-width);
}

h3,
p {
	color: black;
}
p {
	font-size: 1em;
}
h3 {
	font-size: 1.5em;
}

.coming-soon {
	margin-top: 3rem;
	text-align: center;
	font-weight: 600;
}

@media screen and (max-width: 1200px) {
	.grid {
		grid-template-columns: 1fr 1fr 1fr;
		font-size: 1.4vw;
	}
}
@media screen and (max-width: 850px) {
	.grid {
		grid-template: 1fr 1fr / 1fr 1fr;
		font-size: 2.25vw;
	}
	.overlay > .text {
		padding: 0.5rem;
	}
}

@media screen and (max-width: 550px) {
	#hubs {
		--icon-width: 1rem;
	}

  .grid {
    grid-template: 1fr 1fr 1fr / 1fr;
    width: 80%;
    margin: 0 auto;
  }

  .hubs-wrapper {
    margin-top: 0;
  }
  
}
/* Only show bios for desktop */
/* @media screen and (min-width: 850px) {
	.image-wrapper:hover > .bio {
		opacity: 1;
	}
	.image-wrapper:hover > .image-hub {
		filter: brightness(0);
	}
	.image-wrapper:hover > .image-background {
		background: black !important;
	}

  
} */

.image-wrapper:hover {
  transform: scale(1.1);
  opacity: 1;
}

</style>
