<template>
	<div id="faq">
		<h2>Frequently Asked Questions</h2>
		<div class="content">
			<div class="box" v-for="(questionSet, groupTitle) in questions" :key="groupTitle">
				<h3 v-if="groupTitle" class="header">{{groupTitle}}</h3>
				<div  v-for="qa in questionSet" :key="qa">
					<button @click="show(qa.question)" class="question">
						<div class="question-text">{{qa.question}}</div>
						<div :class="{'arrow-active': qa.question == currentQuestion, 'arrow-down': true}"></div>
					</button>
					<transition name="fadeHeight">
						<p v-if="qa.question == currentQuestion" class="p-content" v-html="qa.answer"></p>
					</transition>
				</div>
			</div>
		</div>
		<div class="button-holder">
			<a class="ask" href="mailto:info@cutc.ca">Send us a question!</a>
		</div>
	</div>
</template>

<script>
import Questions from "@/content/faq.json";

export default {
	name: "Faq",
	data() {
		return {
			questions: Questions,
			currentQuestion: ""
		};
	},
	methods: {
		show(question) {
			if (this.currentQuestion == question) this.currentQuestion = "";
			else this.currentQuestion = question;
		}
	}
};
</script>

<style scoped>
#faq {
	width: 80%;
	max-width: 1200px;
	margin: auto;
}
.content {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min(20rem, 100%), 1fr));
	grid-gap: 1em;
}

.box {
	margin-top: 1rem;
}


.header {
	padding: .2em;
	padding-left: .5em;
	background: #e84545;
	border-radius: 3px;
	font-weight: 600;
}
.question {
	overflow-wrap: break-word;
	font-family: inherit;
	background: none;
	background: none;
	font-weight: 600;
	border-radius: 0.02rem;
	border-right: none;
	border-left: none;
	border-color: lightgrey;
	border-bottom: none;
	width: 100%;
	padding: 1rem 0.5rem;
	font-size: 1rem;
	cursor: pointer;
	justify-content: space-between;
	display: flex;
	font-weight: 600;
	color: #1A3467;
}
.p-content {
	padding: 0rem 2rem;
	overflow: hidden;
	color: #1A3467;
	margin-bottom: 16px;
	line-height: 1.75em;
}

.question-text {
	margin-right: 20px;
	text-align: left;
}

.arrow-down {
	/* margin-right: 0.5rem;
	border-top: 0.5rem solid transparent;
	border-bottom: 0.5rem solid transparent;
	border-left: 1.1rem solid #C4C4C4;
	margin-top: 0.15rem;
	transform: matrix(0.02, 1, -1, 0.02, 0, 0); */

	/* display: block; */
  /* margin: 30px auto; */
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	width: 13px;
	height: 13px;
	border-top: 4px solid grey;
	border-left: 4px solid grey;
	transform: rotate(225deg);
	transition: all 0.5s ease;
	/* transform: matrix(0.02, 1, -1, 0.02, 0, 0); */
	overflow: visible;
}
.arrow-active {
	transition: all 0.5s ease;
	transform: rotate(45deg);
	border-color: #B92F21;
	overflow: visible;
}

.button-holder {
	margin-top: 2rem;
	display: grid;
	place-items: center;
}
.ask {
	color: white;
	font-weight: 700;
	font-size: 20px;
	background: #B92F21;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 90%;
	max-width: 360px;
	height: 56px;
	cursor: pointer;
	text-decoration: none;
	padding: 0;
	margin-bottom: 35px;
}

.fadeHeight-enter-active,
.fadeHeight-leave-active {
	transition: all 0.5s;
	max-height: 100vh;
}
.fadeHeight-enter-from,
.fadeHeight-leave-to {
	opacity: 0;
	max-height: 0px;
	overflow: hidden;
}

/* Deep selector due to dynamic content */
.p-content:deep() a {
	color: var(--main-color);
	text-decoration: underline;
	text-decoration-color: var(--main-color);
}

h2 {
	color: #1A3467;
}

@media screen and (max-width: 320px) {
	.ask {
		font-size: 1rem;
	}
}

@media screen and (max-width: 236px) {
	.ask {
		font-size: 0.75rem;
	}
}
</style>
