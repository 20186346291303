<template>
	<div id="meet-team">
		<div class="meet-team-header">Meet the Team</div>
		<!-- map through all team members -->
		<div class="team-pics">
			<div v-for="(member, name) in team" :key="member" class="team-member" v-on:click="memberClick($event, member)">
				<!-- PHOTO -->
        <div class="image-wrapper">
          <img :src="getImage(name)" :alt="name + '\'s Photo'" class="image-member" />
        </div>
				<div class="member-info">
					<!-- NAME -->
					<div class="member-name">{{ name }}</div>
					<!-- ROLE -->
					<div class="member-role">{{ member.role }}</div>
				</div>
			</div>
		</div>

		<a  v-if="limit"  href="" style="text-decoration: none; color: white;">
			<!-- <div class="view-all-button">View all Members</div> -->
      <router-link to="/team" class="view-all-button">View all Members</router-link>
		</a>
	</div>


	<Teleport to="body">
    <Transition name="modal">
      <div v-if="open" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
							<a @click="open = false; member = false" class="close_icon">
                <img :src="crossIcon" class="close-link" />
              </a>
            </div>

            <div class="modal-body">

							<div class="container">
								<div class="container-image">
									<img :src="getImage(member.name)" :alt="name + '\'s Photo'" class="modal-image-member" />
								</div>
								<div class="content">
									<h1 class="title">{{ member.name }}</h1>
									<h2 class="subtitle">{{ member.role }}</h2>
									<a :href="member.linkedIn" target="_blank">
                    <img :src="linkedInImg" :alt="name + '\'s Photo'" class="social-link" />
                  </a>
                  <a :href="member.portfolio" target="_blank" v-if="member.portfolio != ''" style="margin-left: 5px;">
                    <img :src="portfolioImg" :alt="name + '\'s Photo'" class="social-link" />
                  </a>
									<p class="description">{{ member.description }}</p>
								</div>
							</div>

            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script>
// Copied from https://www.notion.so/justinachua/c21925a8417546619ac10ecc28ebeba5?v=e69ab087042641d895b9ac9a4f233c9a
import MembersContent from "@/content/team_updated.json";

export default {
	name: "Team",
	props: {
		limit: Number,
	// 	hasBackground: {
	// 		default: false,
	// 		type: Boolean
	// 	}
	},
	data() {
		return {
			open: false,
			member: false,
			team: MembersContent,
      linkedInImg: null,
      portfolioImg: null,
      crossIcon: null,
			// colors: ["#8394F2", "#F57A75", "#17ADCE", "#F9AFAB", "#44AF69"]
		};
	},
	methods: {
		getImage(name) {
			try {
				// member name is the exact same as the file path in speaker assets
				return require(`@/assets/team_members/${name}.jpg`);
			} catch (e) {
				// throw Error(`pic does not exist: ${name}`);
				// image placeholder when name does not match the filename of a headshot
				return require(`@/assets/team_members/Default.png`);
			}
		},
		memberClick: function(ev, member){
			this.open = true
			this.member = member
		}
	},
	beforeMount() {
		if (this.limit) {
			const sp = Object.entries(this.team).slice(0, this.limit);
			this.team = Object.fromEntries(sp);
		}
	},
  created() {
    this.linkedInImg = require(`@/assets/socials/linkedin.png`);
    this.portfolioImg = require(`@/assets/socials/web.png`);
    this.crossIcon = require(`@/assets/misc/close.png`);
  }
};
</script>

<style scoped>
#meet-team {
	background: var(--main-gradient);
	background-attachment: fixed;
	color: white;
  padding-left: 166px;
  padding-right: 166px;
  padding-top: 60px;
  padding-bottom: 10px;
}

.meet-team-header {
	font-weight: 700;
	font-size: 48px;
	line-height: 52px;
	margin-bottom: 75px;
}

.team-pics {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
  grid-template-columns: 276px 268px 269px 268px;
  grid-template-rows: auto;
  column-gap: 15px;
  row-gap: 10px;
}

.team-member {
	margin: auto;
	flex: 1 0 21%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

  

.image-wrapper {
  display: inline-block;
  position: relative;
  border: 4px solid #F9A594; /* add salmon color border */
  transition: border 0.3s ease;
}


.image-member {
  display: block;
	width: 250px;
	height: 250px;
  object-fit: cover;
	/* margin-bottom: 20px; */
	/* margin-left: 20px; */
	/* margin-right: 20px; */
  filter: grayscale(100%); /* apply grey overlay filter */
  /* outline: 2px solid salmon; */
  transition: filter 0.3s ease; /* animate hover effect */
}


.image-member:hover {
  filter: none;
}

.image-wrapper:hover{
  border: 4px solid rgba(0, 0, 0, 0.025);
}

.member-info{
  margin-top: 10px;
}

.member-name {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	text-align: left;
}

.member-role {
	font-weight: 400;
	font-size: 17px;
	line-height: 28px;
	text-align: left;
	margin-bottom: 50px;
}

.view-all-button {
	color: white;
  font-weight: 700;
  font-size: 20px;
  background: #B92F21;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 360px;
  height: 56px;
  cursor: pointer;
	margin: 25px auto;
  text-decoration: none;
}

/* Modal popup css */

.close_icon{
	float: right;
	text-decoration: none;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 20px;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: 'Montserrat', sans-serif;
  vertical-align: middle;
}

.modal-image-member{
    width: 250px;
    height: 250px;
    border-radius: 10px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}


.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


.social-link{
  width: 30px;
  height: auto;
}

.close-link{
  width: 18px;
  height: auto;
}

.container {    
  padding: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex: 3;
  padding: 0 20px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 24px;
  margin-bottom: 10px;
  color: #103B5F;
}

.description {
  font-size: 18px;
  margin-bottom: 10px;
}


@media screen and (min-width: 875px) {
  .member-info{
    width: 260px;
  }
}

@media screen and (max-width: 875px) {
	.image-member {
		width: 25vw;
		height: auto;
		min-width: 140px;
	}	

  .member-info{
    width: 150px;
  }

  .image-wrapper {
    border: 3px solid #F9A594; /* add salmon color border */
  }

	.modal-container {
    width: 80% !important;
  }
}

@media screen and (max-width: 675px) {
	#meet-team {
		padding: 75px;
	}

	.meet-team-header {
		font-size: 38px;
		margin-bottom: 50px;
	}

	.member-name {
		font-size: 16px;
	}

	.member-role {
		font-size: 13px;
	}
}

@media screen and (max-width: 430px) {
	.meet-team-header {
		font-size: 32px;
	}
    #meet-team {
      padding: 20px;
    }
	.view-all-button {
		font-size: 5vw;
		height: 15vw;
	}
}

@media screen and (max-width: 260px) {
	#meet-team {
		padding: 20px;
	}

	.image-member {
		margin: 0 0 20px 0;
	}
}



@media screen and (max-width: 875px) {
  .modal-container {
    width: 80% !important;
  }
  .container {
    flex-direction: column;
    padding: 20px;
  }
  .container-image {
    margin-bottom: 20px;
  }
  .content {
    padding: 0;
  }
  .title {
    font-size: 28px;
  }
  .subtitle {
    font-size: 20px;
  }
  .description {
    font-size: 16px;
  }
  .social-link {
    width: 25px;
  }
  .close-link {
    width: 14px;
  }
  .modal-image-member {
    width: 160px;
    height: 160px;
  }



  .team-pics {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
  }

}


</style>
