<template>
    <div class="about-grid">
        <div class="mobile-desc-section">
            <div class="mobile-about-header">About CUTC</div>
            <div class="mobile-about-desc">The CUTC focuses on the educational perspective of the technology industry,
                bridging
                students together from
                across the country. This conference is an annual opportunity to network, learn, and engage with other
                students
                and professionals in the industry.</div>
        </div>


        <div class="quote-section">
            <img src="@/assets/about/quotations.png" class="quotes" />
            <div class="short-desc">The CUTC focuses on the educational perspective of the technology industry, bridging
                students together from
                across the country. This conference is an annual opportunity to network, learn, and engage with other
                students
                and professionals in the industry.</div>
            <img src="@/assets/about/quotations-flipped.png" class="quotes second-quotes" />
        </div>

        <div class="desc-section">
            <div class="about-header">About CUTC</div>
            <div class="about-desc">The CUTC focuses on the educational perspective of the technology industry, bridging
                students together from
                across the country. This conference is an annual opportunity to network, learn, and engage with other
                students
                and professionals in the industry.</div>
            <div class="about-desc">Our Vision: For the Canadian Undergraduate Tech Conference to be the centralized hub
                of all conversations and
                opportunities related to computer science and technology targeted at undergraduate students across the
                country.
                The 2023 conference will be a fully in-person event, a combination of speaker series and many
                interactive
                workshops and pitchcase competitions. Undergraduate students interested in technology will gravitate
                towards
                CUTC as their targeted conference and their primary annual source for events and updates in the
                undergraduate
                tech industry.</div>
            <a href="https://web.archive.org/web/20220320185937/http://cutc.ca/#/" target="_blank" style="text-decoration: none; color: white;">
                <div class="conferences-button">Past Conferences</div>
            </a>
        </div>

        <div class="metrics-section">
            <div class="metrics-item">
                <div>21</div>
                <div>Years</div>
            </div>
            <div class="border-right"></div>
            <div class="metrics-item">
                <div>1000+</div>
                <div>Participants</div>
            </div>
            <div class="border-right"></div>
            <div class="metrics-item">
                <div>100+</div>
                <div>Companies</div>
            </div>
            <div class="border-right"></div>
            <div class="metrics-item">
                <div>70</div>
                <div>Speakers</div>
            </div>
        </div>
    </div>
</template>

<!-- <script>
export default {
  data() {
    return {
      logos: [
        "amazon",
        "facebook",
        "github",
        "google",
        "notion",
        "rbc",
        "shopify",
        "stanford",
        "ycombinator",
      ],
      metrics: [
        "21st conference",
        "250+ featured industry leaders",
        "60+ speakers",
        "10K+ students reached",
        "30+ companies represented",
      ],
    };
  },
  methods: {
    getImage(logo) {
      try {
        // speaker name is the exact same as the file path in speaker assets
        return require(`@/assets/about/${logo}.png`);
      } catch (e) {
        throw Error(`pic does not exist: ${logo}`);
        // image placeholder when name does not match the filename of a headshot
      }
    },
  },
};
</script> -->

<style scoped>
.about-grid {
    color: black;
    display: grid;
    grid-template: 1fr auto / 2fr 3fr;
    gap: 100px;
    width: 80%;
    max-width: 1200px;
    margin: 125px auto 150px auto;
}

.quote-section {
    margin-block: auto;
}

.quotes {
    width: 72px;
    margin-block: 22px;
}

.second-quotes {
    margin-left: auto;
    display: block;
}

.short-desc {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.4em;
    color: #103B5F;
}

.desc-section {
    margin-block: auto;
}

.mobile-desc-section {
    display: none;
}

.about-header {
    font-weight: 700;
    font-size: 48px;
    line-height: 1.08em;
    letter-spacing: -0.1px;
    margin-bottom: 35px;
}

.mobile-about-header {
    line-height: 1.08em;
    letter-spacing: -0.1px;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
    color: #1A3467;
}

.about-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.75em;
    color: #0C2D48;
    margin-bottom: 35px;
}

.mobile-about-desc {
    font-weight: 400;
    margin-bottom: 35px;
    font-size: 14px;
    line-height: 1.43em;
    color: #0C2D48;
}

.conferences-button {
    color: white;
    font-weight: 700;
    font-size: 20px;
    background: #B92F21;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    max-width: 360px;
    height: 56px;
    cursor: pointer;
}

.metrics-section {
    display: flex;
    gap: 30px;
    grid-column: 1/3;
    justify-content: space-between;
}

.metrics-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.border-right {
    background: #D8D8D8;
    width: 1px;
    height: 60%;
    align-self: center;
}

.metrics-item> :first-child {
    color: #9D516E;
    font-weight: 800;
    font-size: 87px;
}

.metrics-item> :nth-child(2) {
    font-weight: 700;
    font-size: 28px;
}

@media screen and (max-width: 1100px) {

    .short-desc {
        font-size: 24px;
    }

    .metrics-item> :first-child {
        font-size: 67px;
    }

    .metrics-item> :nth-child(2) {
        font-size: 18px;
    }
}

@media screen and (max-width: 900px) {

    .short-desc {
        font-size: 22px;
    }

    .metrics-item> :first-child {
        font-size: 47px;
    }

    .metrics-item> :nth-child(2) {
        font-size: 18px;
    }

    @media screen and (max-width: 768px) {
        .about-grid {
            display: block;
            /* margin-bottom: 40px; */
        }

        .quotes {
            height: 60px;
        }

        .quote-section {
            position: relative;
        }

        .conferences-button {
            margin: 20px auto;
        }

        .metrics-section {
            width: 100%;
            overflow: hidden;
            display: grid;
            grid-template: 1fr 1fr / 1fr 1fr;
        }

        .border-right {
            display: none;
        }
    }

    @media screen and (max-width: 380px) {

        .about-grid {
            margin: 32px 15px;
            max-width: calc(100% - 30px);
            width: calc(100% - 30px);
        }

        .mobile-desc-section {
            display: block;
        }

        .about-header,
        .about-desc {
            display: none;
        }

        .metrics-item> :first-child {
            font-size: 48px;
        }

        .metrics-item> :nth-child(2) {
            font-size: 20px;
        }

        .short-desc {
            font-size: 20px;
        }

        .quote-section {
            margin: 0 15px;
        }

        .quotes {
            margin-top: 0;
            margin-bottom: 20px;
        }

        .second-quotes {
            margin-top: 20px;
        }

        

    }

    @media screen and (max-width: 290px) {
        .conferences-button {
            font-size: 15px;
            height: 40px;
        }
    }

    @media screen and (max-width: 250px) {
        .metrics-item> :first-child {
            font-size: 20px;
        }

        .metrics-item> :nth-child(2) {
            font-size: 10px;
        }

        .quotes {
            height: 40px;
            width: auto;
        }
    }

    @media screen and (max-width: 220px) {
        .conferences-button {
            font-size: 10px;
            height: 30px;
        }
    }
}
</style>
