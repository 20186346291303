<template>
<div id="hero" >
	<img src="@/assets/misc/banner-wo-bg.png" id="cutc-building-image">
	
	<!-- <div class="spacer"></div> --> 
	<span class ="hero-info">
		<div class="hero-text">
			<h1 class="hero-title">Canadian Undergraduate Technology Conference</h1>
			<h3>May 6th, 2023 | OneEleven, Toronto</h3>
			
			<!-- <div class="data">
				<p class="margin-right10"><span class="timer_style">{{days}}</span>d</p>
				<p class="margin-right10"><span class="timer_style">{{hours}}</span>h</p>
				<p class="margin-right10"><span class="timer_style">{{minutes}}</span>m</p>
				<p class="margin-right10"><span class="timer_style">{{seconds}}</span>s</p>
			</div> -->

			<a class="mailing-button disabled" href="https://lu.ma/cutc2023" target="_blank">Registeration Closed</a>
			<p>Thank you for attending CUTC</p>
			<!-- <SubscribeModal /> -->
		</div>
		<!-- <register-button/> -->
	</span>
	<div class="building" v-cloak>
		<!-- <countdown/> -->
		<!-- <img src="@/assets/misc/banner-wo-bg.png" id="cutc-building-image"> -->
	</div>

	
</div>
</template>

<script>
// import Countdown from '@/components/homePage/Countdown.vue';
// import RegisterButton from '@/components/general/RegisterButton.vue';

// import SubscribeModal from './SubscribeModal.vue';

const conferenceDate = new Date('May 6, 2023 9:00:00');
export default {
	name: 'Hero',
	components: {
    // SubscribeModal
},
	data: () => ({
		email: '',
		signup: true,
		days: 10,
		hours: 12,
		minutes: 51,
		seconds: 0,
	}),
	methods: {
		updateTimeStamp() {
			const now = Date.now();
			const diff = new Date(conferenceDate - now);
			this.days = Math.round(diff / 1000 / 60 / 60 / 24);
			this.hours = diff.getHours();
			this.minutes = diff.getMinutes();
			this.seconds = diff.getSeconds();
		}
	},
	beforeMount() {
		this.updateTimeStamp();
		setInterval(this.updateTimeStamp, 1000);
	}
}
</script>

<style scoped>


.metrics-item> :first-child {
    color: #F9A594;
    font-weight: 800;
    font-size: 87px;
}

.timertext{
	text-align: center;
}

.metrics-section {
		width: 60%;
    display: flex;
    gap: 30px;
    grid-column: 1/4;
		margin: auto;
    justify-content: space-between;
}

.data {
	display: inline-flex;
	grid-template-columns: repeat(4, 1fr);
}

.margin-right10{
	margin-right: 10px;
}
.timer_style{
	font-size: 2rem;
}

#hero {
	display: flex;
	align-items: center;
	padding: 4rem var(--splash-page-inset) 6rem;
}

#timersection{
	/* display: flex; */
	align-items: center;
	padding: 0rem var(--splash-page-inset) 8rem;
}

@media screen and (max-width: 850px) {
	#hero {
		flex-direction: column;
	}

	#timersection {
		flex-direction: column;
	}
}

.spacer {
	margin: 1rem;
}
@media screen and (max-width: 850px) {
	.spacer {
		margin: 0.5rem;
	}

	.metrics-section {
		width: 90%;
    display: flex;
    gap: 5px;
    grid-column: 1/4;
		margin: auto;
    justify-content: space-between;
	}

}




@media screen and (min-width: 850px) {
	.mailing-button {
			width: 15vw;
	}
	
	#cutc-building-image {
		position: absolute;
		/* max-height: 55vh; */
		width: 70%;
		height: auto;
		justify-self: end;
		right: 5vh;
	}

	#hero{
		height: 50vh;
	}

	#timersection{
		height: 5vh;
	}


}

@media screen and (max-width: 1600px) {
	#cutc-building-image {
		right: 0vh;
		width: 90%;
		height: auto;
		top: auto;
	}
}

@media screen and (max-width: 1480px) {
	#cutc-building-image {
		width: 80%;
	}

	.hero-title {
		font-size: 2.5em;
	}
}

@media screen and (max-width: 1275px) {
	#cutc-building-image {
		top: 15vh;
	}
}

@media screen and (max-width: 1000px) {
	#cutc-building-image {
		top: 20vh;
	}
}

.hero-text {
	margin-bottom: 1.5rem;
	overflow: hidden;
}

.hero-title {
	margin-bottom: 1rem;
}

.hero-info {
  flex-basis: 100%;
  flex: 1;
	z-index: 2;
}
.building {
  position: relative;
  height: min-content;
  flex-basis: 100%;
  flex: 1;
}


.slogan {
	font-size: 2em;
	font-weight: 600;
	color: white;
	margin: 0 !important;
}
@media screen and (max-width: 850px) {
	.slogan {
		font-size: 1.5em;
	}

	#cutc-building-image {
		display: none;
	}

}

@media screen and (max-width: 375px) {
	.hero-title {
		font-size: 2em;
	}
}

@media screen and (max-width: 283px) {
	.hero-title {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1em;
	}

}

form {
	display: flex;
	position: relative;
	justify-content: flex-start;
	margin: 1em 0;
}

.email-input {
	display: flex;
	width: 100%;
}

button {
	height: 100%;
	width: 60px;
	border: none;
	background-color:#A3241D;
	border-top-right-radius: 45%;
	border-bottom-right-radius: 45%;
	margin: 0;
}
button:focus {
	outline: none;
}

input[type="email"] {
	width: 95%;
	max-width: 400px;
	border: 1px solid #A3241D;
	border-right-width: 0;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	padding: 1rem;
	margin: 0;
}
input:focus {
	outline:none;
}

a {
	font-weight: 500;
	display: block;
	margin-bottom: 2em;
	color: white;
}



.mailing-button {
  color: white;
  font-weight: 700;
  font-size: 20px;
  background: grey;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 328px;
  height: 56px;
  cursor: pointer;
  white-space: nowrap;
  margin-top: 4vh;
  text-decoration: none;
}


#cutc-building-image{
	z-index: 0;
}



@media screen and (max-width: 500px) {
  .mailing-button {
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    max-width: 360px;
  }
}

@media screen and (max-width: 380px) {
  .mailing-button {
    width: 90%;
  }
}

@media screen and (max-width: 350px) {
  .mailing-button {
    font-size: 15px;
  }
}

@media screen and (max-width: 300px) {
  .mailing-button {
    white-space:break-spaces;
    text-align: center;
    width: 90%;
  }
}


/* timer media queries css */


@media screen and (max-width: 1100px) {
	.metrics-item> :first-child {
			font-size: 45px;
	}
}

@media screen and (max-width: 900px) {
	.metrics-item> :first-child {
			font-size: 35px;
	}
}

@media screen and (max-width: 380px) {
		.metrics-item> :first-child {
				font-size: 28px;
		}
}

@media screen and (max-width: 250px) {
		.metrics-item> :first-child {
				font-size: 10px;
		}
}

</style>
