<template>
  <div>
    <nav-bar/>
      <div class="team-wrapper">
        <MeetTheTeam />
      </div>
    <bottom/>
  </div>
  </template>
  
  <script>
  import NavBar from '@/components/general/NavBar.vue';
  import MeetTheTeam from '@/components/general/MeetTheTeam.vue';
  import Bottom from '@/components/general/Footer.vue'
  
  export default {
    name: 'TeamPage',
    components: {
      NavBar,
      MeetTheTeam,
      Bottom,
    }
  }
  </script>
  
  <style scoped>

  </style>
  