<template>
<div id="sponsors">
	<h2 class="sponsors-title">Sponsors</h2>
	<div class="mobile">
		<a
			v-for="(info, sponsor) in sponsors"
			:key="sponsor"
			:href="info.link"
			:class="info.class"
			target="_blank"
		>
			<img :src="getImage(sponsor)" :alt="sponsor">
		</a>
	</div>
	<div class="desktop">
		<a
			v-for="(info, sponsor) in sponsors"
			:key="sponsor"
			:href="info.link"
			:class="info.class"
			target="_blank"
		>
			<img class="sponsorimg" :src="getImage(sponsor)" :alt="sponsor">
		</a>
	</div>
</div>
<!-- <div id="sponsors">
	<h2 class="sponsors-title">Sponsors from previous years</h2>
	<div class="mobile">
		<a
			v-for="(info, sponsor) in sponsors"
			:key="sponsor"
			:href="info.link"
			:class="info.class"
			target="_blank"
		>
			<img :src="getImage(sponsor)" :alt="sponsor">
		</a>
	</div>
	<div class="desktop">
		<a
			v-for="(info, sponsor) in sponsors"
			:key="sponsor"
			:href="info.link"
			:class="info.class"
			target="_blank"
		>
			<img class="sponsorimg" :src="getImage(sponsor)" :alt="sponsor">
		</a>
	</div>
</div> -->
</template>

<script>
import sponsorData from "@/content/sponsors.json";

export default {
	name: 'Sponsorship',
	data() {
		return {
			sponsors: sponsorData,
			width: {
				gold: "100%",
				silver: "100%",
				bronze: "100%",
				partner: "100%"
			},
			height: {
				gold: "100%",
				silver: "100%",
				bronze: "100%",
				partner: "100%"
			}
		}
	},
	methods: {
		getImage(name) {
			try {
				return require(`@/assets/sponsors/2023-sponsors/${name}.png`);
			} catch (e) {
				// throw Error(`pic does not exist: ${name}`);
			}
		}
	}
}
</script>

<style scoped>
/* DESKTOP CSS */
img {
    height: 180px;
    object-fit: contain;
    width: 20%;
    margin: 0vh 2vw 2vw 0;
    max-width: 300px;
}

.desktop {
	position: relative;
	/* margin-top: 8vh; */
	max-width: 100%;
	overflow: hidden;
	margin-top: 50px;
}
.link {
	position: absolute;
	/* border: 1px solid grey; */
}

#sponsors {
	max-width: calc(100%);
	overflow: hidden;
	margin: 75px 50px 75px 166px;
}

.sponsors-title {
	color: rgb(190, 112, 142);
	font-size: 3em;
	width: fit-content;
	margin-top: 0.2em!important;
}

@media screen and (max-width: 1300px) {
	#sponsors {
		margin-left: 125px;
	}
}

@media screen and (max-width: 1000px) {
	#sponsors {
		margin-left: 50px;
	}
}

@media screen and (max-width: 850px) {
	.sponsors-title {
		font-size: 2.5em;
		margin-bottom: 1em;
	}

	.desktop {
		display: grid;
		grid-template: auto / 1fr 1fr;
		gap: 1em;
	}

	.gold, .partner, .bronze, .silver {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	img {
		width: 80%;
		margin: 0;
	}
}

@media screen and (max-width: 715px) {
	.sponsors-title {
		font-size: 2em;
	}
}

@keyframes breathe {
	from {
		box-shadow: 0px 0px 3px 0px rgba(180, 110, 110, 0);
	}
	20% {
		box-shadow: 0px 0px 3px 3px rgba(255, 100, 100, 0.4);
	}
	to {
		box-shadow: 0px 0px 3px 9px rgba(255, 100, 100, 0);
	}
}

.mobile {
	display: none;
}

/* Mobile CSS */
@media screen and (max-width: 550px) {

	.sponsors-title {
		margin-bottom: 0.2em;
	}

	img {
		height: 167px;
		object-fit: contain;
		width: 60%;
		margin: 3vh 2vw 2vw 0;
		max-width: 300px;
	}

	#sponsors {
		--total-width: 30rem;
	}
	.desktop {
		display: none;
	}

	.mobile {
		width: 100%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	text-align: center;
	}

	.mobile > a {
		margin-top: .5rem;
		margin-bottom: 0;
		transition: all .3s ease;
	}

	.gold {
		width: var(--total-width);
		max-width: 100%;
	}

	.silver {
		width: var(--total-width);
		max-width: 100%;
	}

	.bronze {
		width: var(--total-width);
		max-width: 100%;
	}

	.partner {
		width: var(--total-width);
		max-width: 100%;
	}

	.center {
		margin: auto;
	}
}

@media screen and (max-width: 450px) {
	img {
		width: 80%;
	}
}
</style>
