<template>
<div id="schedule">
	<nav-bar/>

	<div class="page">

		<div class="content">
			<div>
				<h2 style="margin: 16px 16px 16px 0px; color: #081B29;"> 2023 Schedule </h2>
				<p style="font-weight:650; margin: 16px 16px 16px 0px; font-size: small;"> Eastern Time (US & Canada)  </p>
			</div>

			<p style="margin-top: 48px;">
				<span style="font-weight:650; font-size:large;">Filters  </span>
				<span v-if="value1.length > 0 || value2.length > 0" @click="removeAllValues()" style="margin-left: 16px; cursor: pointer; font-weight: 500; font-size: 16px; line-height: 20px; text-decoration-line: underline; color: #081B29;">Clear all</span> 
			</p>
			



			<div class="top-info-section">
				<p v-for="topic in [...value1, ...value2]" :key="topic" class="topic-tag" >
					<span @click="removeValue(topic)" style="cursor: pointer;">X &nbsp;</span> 
					<span>{{ topic['name'] }}</span>
				</p>
				
			</div>


			<div class="dropdownGroup">
				<div class="dropdown1">
					<multiselect v-model="value1" :options="formats" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Format" label="name" track-by="name" :preselect-first="false" deselect-label="Remove" select-label="">
						<template v-slot:selection="{ values, isOpen }" >
							<span class="multiselect__single" v-if="values.length" v-show="!isOpen">Format ({{ values.length }})</span>
						</template>
					</multiselect>
				</div>

				<div class="dropdown2">
					<multiselect v-model="value2" :options="topics" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Topics" label="name" track-by="name" :preselect-first="false" deselect-label="Remove" select-label="">
						<template v-slot:selection="{ values, isOpen }" ><span class="multiselect__single" v-if="values.length" v-show="!isOpen">Topics ({{ values.length }})</span></template>
					</multiselect>
				</div>

			</div>


<!-- 						
			<select style="width: 150px; margin: 16px; border-radius: 5px; font-size: 15px; padding: 10px;" name="format" id="format">
				<option v-for="format in allFormats" :key="format">
					{{ format }}
				</option>	
			</select>
			
			<select style="width: 150px; margin: 16px; border-radius: 5px; font-size: 15px; padding: 10px;" name="topics" id="topics">
				<option v-for="topic in allTopics" :key="topic">
					{{ topic }}
				</option>				
			</select> -->

			<div class="top">
				<span v-for="(_, day) in scheduleData" :key="day">
					<div
						:class="{'dateButton': true, 'dateButton-active': day == activeDay}"
						@click="activeDay = day"
						v-if="day.length"
						>{{ day }}
					</div>
				</span>
			</div>

			<div class="schedule" v-for="(events, day) in scheduleData" :key="day">
				
					
				<div v-for="(tileInfo) in events" :key="tileInfo.title">
					
							<schedule-tile
								v-if="isChecked(tileInfo.topics, tileInfo.format, value1, value2)"
								class="tile-indent"
								v-bind="tileInfo"
								:start="getTime(tileInfo.start)"
								:stop="getTime(tileInfo.stop)"
								:day="day"/>
								
				</div>
			</div>

		</div>
	</div>
	<bottom/>

	<transition name="fade-in">
		<div class="dimmer" v-if="isMobile && showMobileMenu" @click="showMobileMenu = false"/>
	</transition>
</div>
</template>

<script>

import Multiselect from 'vue-multiselect'

import NavBar from '@/components/general/NavBar.vue';
import ScheduleTile from "@/components/general/ScheduleTile.vue";
import Bottom from '@/components/general/Footer.vue'

import ScheduleContent from '@/content/schedule.json';
var moment = require('moment-timezone');

export default {
	name: 'Schedule',
	components: {
		NavBar,
		ScheduleTile,
		Bottom,
		Multiselect
	},
	data() {
		return {
			scheduleData: ScheduleContent,
			allTopics: [],
			checkedTopics: [],
			allFormats: [],
			checkedFormats: [],
			activeDay: '',
			displayTopics: true,
			displayFormats: true,
			isMobile: false,
			showMobileMenu: false,
			colors: {
				"Workshop": "#8394F2",
				"Panel": "#17AECE",
				"Keynote": "#FFA500",
				"Lightning Talk": "#F9AFAB",
				"Fireside Chat": "#F57A75",
				"Breakout Session": "#98D485",
				"Default": "lightgrey" // Misc
			},
			value1: [],
      formats: [
        { name: 'Keynote'},
        { name: 'Panel'},
        { name: 'Workshop'},
        { name: 'Lightning Talk'},
        { name: 'Breakout Session'},
      ],
			value2: [],
      topics: [
        { name: 'Career'},
        { name: 'Business'},
        { name: 'Amplify'},
        { name: 'SWE'},
        { name: 'Entrepreneurship'},
        { name: 'D&I'},
        { name: 'Product'},
      ]
		}
	},
	methods: {
		getTime(stamp) {
			if (stamp) {
				let date = moment(stamp, 'H:mm')
				date = date.add(4, 'hours') // Dates are in EST
				date = date.add(moment.parseZone().utcOffset(), 'minutes')
				return date.format("h:mm A")
			}
			return ''
		},
		getTimeZone() {
			var zone_name =  moment.tz.guess();
			return moment.tz(zone_name).zoneAbbr();
		},
		isChecked(topics, format, value1, value2) {
			console.log(value1, value2)
			console.log(topics, format)

			// Topic all should match
			for (let i = 0; i < value2.length; i++ ) {
				if (topics.indexOf(value2[i]['name']) == -1) {
					return false
				}
			}

			// Format anyone matches
			if (value1.length > 0) {
				for (let i = 0; i < value1.length; i++ ) {
					if (format == value1[i]['name']) {
						return true
					}
				}

				return false
			}

			return true
		},
		handleResize() {
			this.isMobile = window.innerWidth <= 850;
		},
		hourNorm(stamp) {
			const hours = stamp.split(':')[0];
			return hours + ':00';
		},
		showTimeDivider(day, i) {
			const prevTile = this.scheduleData[day][i - 1];
			const currTile = this.scheduleData[day][i];
			if (!prevTile || !prevTile.start) return true;
			if (!currTile || !currTile.start) return false;
			return this.hourNorm(prevTile.start) != this.hourNorm(currTile.start);
		},
		removeValue(value){
			const idx = this.value1.indexOf(value)
			if(idx != -1){
				this.value1.splice(idx, 1)
				return
			}

			const idx2 = this.value2.indexOf(value)
			if(idx2 != -1){
				this.value2.splice(idx2, 1)
			}
		},
		removeAllValues(){
			this.value1 = [];
			this.value2 = [];
		}
	},
	created() {
		this.handleResize();
		window.addEventListener('resize', () => this.handleResize());
	},
	beforeMount() {
		let topics = []
		let formats = []
		for(let i in this.scheduleData){
			for(let k in this.scheduleData[i]){
				for(let l in this.scheduleData[i][k]["topics"]){
					const topic = this.scheduleData[i][k]["topics"][l];
					if (topic) topics.push(topic)
				}
				const format = this.scheduleData[i][k]["format"];
				if (format) formats.push(format)
			}
		}
		this.topics = [...new Set(topics)].map((val) => { return {"name": val} })
		this.formats = [...new Set(formats)].map((val) => { return {"name": val} })
		this.activeDay = Object.keys(this.scheduleData)[0]
	}
}
</script>


<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->

<style scoped>


/* Multiselect css start */
.top-info-section {
	display: flex;
	flex-wrap: wrap;
}
.top-info-section > :not(:last-child) {
	margin-right: 0.5rem;
}
/* Icons */
.top-info-section > img {
	width: 0.7rem;
}

.topic-tag {
	padding: 0.25rem 1rem;
	border-color: black;
	border: 1px solid grey;
	font-weight: 500;
	border-radius: 20px;
	font-size: 1em;
	margin: 10px 0px;
}

.dropdownGroup{
	display: inline-flex; 
	margin-top: 16px;
}

.dropdown1{
	width: 229px;
}
.dropdown2{
	width: 229px;
	margin-left: 50px;
}

.multiselect__placeholder {
    color: #081B29 !important;
}


@media screen and (max-width: 850px) {

	.dropdownGroup{
		width: 100%;
	}

	.dropdown1{
		width: 100%;
	}
	.dropdown2{
		width: 100%;
		margin-left: 5px;
	}

	.content {
		padding: 10px !important;
	}
}


/* Multiselect css stop */

#schedule {
	--desktop-sidebar-width: 20rem;
	--border-divider-color: #CCCCCC;
}

.page {
	display: flex;
}

h4, p {
	color: black;
}
.time > h4 {
	padding-right: .25em;
	width: max-content;
}

.sidebar {
	width: var(--desktop-sidebar-width);
	height: 100%;
	padding: 1em;
}
.sidebar-title {
	margin-bottom: 0.5em;
}
.sidebar-spacer {
	margin-bottom: 2em;
}

.content {
	width: 100%;
	padding:  24px 100px 24px 100px;
	/* border-left: 1px solid var(--border-divider-color); */
}

.top {
	display: flex;
	/* border-bottom: 1px solid var(--border-divider-color); */
	flex-wrap: wrap;
	align-items: center;
}
.top > .spacer {
	flex-grow: 1;
}
.top > p {
	margin-right: 1rem;
}

.time {
	display: flex;
	align-items: center;
	margin-top: 2%;
}
.time hr {
	flex-grow: 1;
	border: none;
	border-bottom: 2px solid var(--border-divider-color);
	margin: 1rem;
}
.tile-indent {
	/* margin-left: 16px; */
}

.dateButton {
	font-size: 1.2em;
	color: black;
	border-radius: 60px;
	margin: 35px 1rem 1rem 0rem;
	padding: 0.5rem 0rem;
	font-weight: 700;
}
.dateButton:hover {
	cursor: pointer;
}
.dateButton-active {
	color: black;
	text-decoration: underline #F9A594;
	text-decoration-thickness: 6px;
	text-underline-offset: 10px;
}

.schedule {
	/* margin: 1rem; */
}

.checkbox-row {
	display: flex;
	align-items: center;
	color: black;
	font-size: .8rem;
	margin-bottom: .5rem;
}
.checkbox-row>input {
	margin: 0;
	margin-left: 1rem;
	-webkit-appearance: none;
	border: 2px solid #666666;
	padding: .4rem;
	border-radius: 2px;
}
.checkbox-row>input:checked {
	border: 2px solid #C8190F;
	background: #C8190F;
	color: white;
}
.checkbox-row>label {
	margin-top: .1rem;
}

.checkbox-row>:not(:last-child) {
	margin-right: .25rem;
}

.mobile-menu-button {
	display: none;
}

@media screen and (max-width: 850px) {
	.sidebar {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 5;
		width: 100vw;
		height: fit-content;
		padding-bottom: 5%;
		font-size: .8em;
		box-sizing: border-box;

		border: 1px solid var(--border-divider-color);
		background: white;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}
	.dimmer {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.1643) 0%, rgba(0, 0, 0, 0.53) 25%);
		z-index: 3;
		opacity: 1;
	}

	.mobile-menu-button {
		position: fixed;
		z-index: 7;
		display: grid;
		place-items: center;

		font-size: 1.5rem;

		bottom: 5%;
		right: 5%;
		width: 3rem;
		height: 3rem;
		border-radius: 20rem;
		background: red;
	}

	.dateButton {
		margin: .5rem;
		padding: .75rem 1rem;
	}
	.schedule {
		margin: .5rem;
	}
	.tile-indent {
		margin-left: 0;
	}
}

.container {
  display: flex;
	align-items: center;
  position: relative;
	color: black;
  padding-left: 32px;
  margin-bottom: 12px;
	padding-top: 1px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dot {
	background: var(--dot-color);
	height: 8px;
	width: 8px;
	margin-right: 8px;
	border-radius: 999px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
	border: 1.5px solid darkgray;
	border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--dot-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6.5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fade-in-enter-active,
.fade-in-leave-active {
	transition: all 0.5s;
}
.fade-in-enter-from,
.fade-in-leave-to {
	opacity: 0;
}
.pop-up-enter-active,
.pop-up-leave-active {
	transition: bottom 0.5s;
}
.pop-up-enter-from,
.pop-up-leave-to {
	/* // ! This could appear when it's not supposed to */
	bottom: -100vh;
}
</style>
